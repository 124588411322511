import React from 'react'
import { useCommunicationSettingsContext } from './communication-settings-context'
import SaveActionsBtn from '../save-changes-btn'

export default function MiscSaveActions() {
    const { canSave, isUpdateLoading, handleUpdateSettings } =
        useCommunicationSettingsContext()

    return (
        <SaveActionsBtn
            canSave={canSave}
            isUpdateLoading={isUpdateLoading}
            handleUpdateSettings={handleUpdateSettings}
        />
    )
}
