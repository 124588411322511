import React from 'react'
import SharedStack from '~components/shared/wrappers/shared-stack'
import SharedHelpText from '~components/shared/form/shared-help-text'
import SettingSwitch from '../form/setting-switch'
import SettingSelect from '../form/setting-select'
import { useCommunicationSettingsContext } from './communication-settings-context'
import {
    writebackBufferOptions,
    writebackBeforeApptOptions,
} from '../constants'
import { getMinutesFromMap } from '../constants'

export default function WritebackSettings() {
    const {
        isWritebackEnabled,
        setIsWritebackEnabled,
        shouldDailyWriteback,
        setShouldDailyWriteback,
        // usePdfForWriteback,
        // setUsePdfForWriteback,
        writebackBufferMinutes,
        setWritebackBufferMinutes,
        writebackBeforeApptMins,
        setWritebackBeforeApptMins,
        shouldWritebackOnSend,
        setShouldWritebackOnSend,
        shouldWritebackOnReceive,
        setShouldWritebackOnReceive,
        shouldWritebackNotifs,
        setShouldWritebackNotifs,
        shouldUseAiWriteback,
        setShouldUseAiWriteback,
    } = useCommunicationSettingsContext()

    return (
        <SharedStack spacing={4}>
            <SettingSwitch
                label="Enable communication writeback to the PIMS?"
                isChecked={isWritebackEnabled}
                onChange={e => setIsWritebackEnabled(e.target.checked)}
            />
            <SettingSwitch
                label="Enable daily writeback?"
                isChecked={shouldDailyWriteback}
                isDisabled={!isWritebackEnabled}
                onChange={e => setShouldDailyWriteback(e.target.checked)}
                moreInfo={
                    <SharedHelpText fontSize="sm" mb={0}>
                        When enabled, communications will be written back to
                        PIMS once per day. This overrides all other settings for
                        automated writeback except for instant writeback on send
                        and receive.
                    </SharedHelpText>
                }
            />
            {/* <SettingSwitch
                label="Use PDF format for writeback?"
                isChecked={usePdfForWriteback}
                isDisabled={!isWritebackEnabled}
                onChange={e => setUsePdfForWriteback(e.target.checked)}
            /> */}
            <SettingSelect
                label="How long to wait before writing back communications?"
                value={writebackBufferOptions[writebackBufferMinutes]}
                setValue={(val: string) => {
                    setWritebackBufferMinutes(
                        getMinutesFromMap(val, writebackBufferOptions),
                    )
                }}
                options={Object.values(writebackBufferOptions)}
                isDisabled={!isWritebackEnabled}
                moreInfo={
                    <SharedHelpText fontSize="sm" mb={0}>
                        If no messages have been sent or received after this
                        amount of time, messages will be written back to the
                        PIMS
                    </SharedHelpText>
                }
            />
            <SettingSelect
                label="When to write back appointment-related communications?"
                value={writebackBeforeApptOptions[writebackBeforeApptMins]}
                setValue={(val: string) => {
                    setWritebackBeforeApptMins(
                        getMinutesFromMap(val, writebackBeforeApptOptions),
                    )
                }}
                options={Object.values(writebackBeforeApptOptions)}
                isDisabled={!isWritebackEnabled}
                moreInfo={
                    <SharedHelpText fontSize="sm" mb={0}>
                        Unwritten communications will be written back to the
                        PIMS at this point before the appointment
                    </SharedHelpText>
                }
            />
            <SettingSwitch
                label="Instantly write back messages when sent?"
                isChecked={shouldWritebackOnSend}
                isDisabled={!isWritebackEnabled}
                onChange={e => setShouldWritebackOnSend(e.target.checked)}
            />
            <SettingSwitch
                label="Instantly write back messages when received?"
                isChecked={shouldWritebackOnReceive}
                isDisabled={!isWritebackEnabled}
                onChange={e => setShouldWritebackOnReceive(e.target.checked)}
            />
            <SettingSwitch
                label="Write back system notification messages?"
                isChecked={shouldWritebackNotifs}
                isDisabled={!isWritebackEnabled}
                onChange={e => setShouldWritebackNotifs(e.target.checked)}
            />
            <SettingSwitch
                label="Use AI to decide to writeback messages?"
                isChecked={shouldUseAiWriteback}
                isDisabled={!isWritebackEnabled}
                onChange={e => setShouldUseAiWriteback(e.target.checked)}
                moreInfo={
                    <SharedHelpText fontSize="sm" mb={0}>
                        When enabled, AI will be used to decide whether to
                        writeback messages
                    </SharedHelpText>
                }
            />
        </SharedStack>
    )
}
