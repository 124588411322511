import React, { useEffect, useState } from 'react'
import { Icon } from '@chakra-ui/icon'
import { Button } from '@chakra-ui/button'
import { BiMinusCircle, BiPlusCircle } from 'react-icons/bi'
import {
    Box,
    Text,
    Flex,
    VStack,
    SimpleGrid,
    Center,
    SimpleGridProps,
} from '@chakra-ui/layout'

import {
    RepeatedAvailability,
    getRepeatedAvailability,
    Days,
    getAvailabilityHours,
} from '~utils/availability-helpers'
import TimeIntervalBlock from './time-interval-block'
import {
    AvailabilityHoursInput,
    TimeInterval,
} from '~graphql/generated/graphql'
import DayButton from './day-button'
import { HStack } from '@chakra-ui/react'
import IntervalsEdit from './intervals-edit'

interface Props {
    hours?: AvailabilityHoursInput
    setHours: (e: AvailabilityHoursInput) => void
    gridProps?: SimpleGridProps
}

const RepeatedAvailabilityForm: React.FC<Props> = ({
    hours,
    setHours,
    gridProps,
}) => {
    const initialHours = JSON.stringify(hours)
    const [repeatedAvailability, setRepeatedAvailability] = useState<
        RepeatedAvailability[]
    >(
        getRepeatedAvailability(hours || {}).filter(
            d => d.intervals && d.intervals.length > 0
        )
    )

    useEffect(() => {
        if (
            initialHours === JSON.stringify(hours) &&
            initialHours !==
                JSON.stringify(getAvailabilityHours(repeatedAvailability))
        ) {
            setRepeatedAvailability(
                getRepeatedAvailability(hours || {}).filter(
                    d => d.intervals && d.intervals.length > 0
                )
            )
        }
    }, [hours])

    useEffect(() => {
        if (repeatedAvailability.length > 0) {
            setHours(getAvailabilityHours(repeatedAvailability))
        }
    }, [repeatedAvailability])

    function updateDays(index: number, day: Days, isRemoving?: boolean) {
        setRepeatedAvailability(
            repeatedAvailability.map((r, i) => {
                if (i === index) {
                    return {
                        ...r,
                        days: isRemoving
                            ? r.days.filter(d => d !== day)
                            : [...r.days, day],
                    }
                } else if (r.days.includes(day)) {
                    return {
                        ...r,
                        days: r.days.filter(d => d !== day),
                    }
                } else {
                    return r
                }
            })
        )
    }

    function updateIntervals(index: number, intervals: TimeInterval[]) {
        setRepeatedAvailability(
            repeatedAvailability.map((r, i) => {
                if (i === index) {
                    return {
                        ...r,
                        intervals: intervals,
                    }
                } else {
                    return r
                }
            })
        )
    }

    function removeSchedule(index: number) {
        setRepeatedAvailability(
            repeatedAvailability.filter((_, i) => i !== index)
        )
    }

    return (
        <SimpleGrid
            columns={{ base: 1, md: 2, xl: 3 }}
            gridGap={6}
            {...gridProps}
        >
            {repeatedAvailability.map((day, index) => (
                <RepeatedAvailabilityCard
                    key={index}
                    availability={day}
                    availabilityIndex={index}
                    updateDays={updateDays}
                    updateIntervals={updateIntervals}
                    removeSchedule={removeSchedule}
                />
            ))}
            {!Object.values(Days).every(d =>
                repeatedAvailability.some(r => r.days.includes(d))
            ) &&
                !repeatedAvailability.some(r => r.days.length === 0) && (
                    <Center
                        borderWidth={1}
                        borderColor="gray.100"
                        cursor="pointer"
                        _hover={{
                            bg: 'gray.50',
                        }}
                        minH="156px"
                        minW="248px"
                        rounded="lg"
                        onClick={() => {
                            setRepeatedAvailability([
                                ...repeatedAvailability,
                                {
                                    days: [
                                        ...Object.values(Days).filter(
                                            d =>
                                                !repeatedAvailability.some(r =>
                                                    r.days.includes(d)
                                                )
                                        ),
                                    ],
                                    intervals: [],
                                },
                            ])
                        }}
                    >
                        <Icon as={BiPlusCircle} mr={2} />
                        Add a{repeatedAvailability.length > 0
                            ? 'nother'
                            : ''}{' '}
                        schedule
                    </Center>
                )}
        </SimpleGrid>
    )
}

const RepeatedAvailabilityCard: React.FC<{
    availability: RepeatedAvailability
    availabilityIndex: number
    updateDays: (index: number, day: Days, isRemoving?: boolean) => void
    updateIntervals: (index: number, intervals: TimeInterval[]) => void
    removeSchedule: (index: number) => void
}> = ({
    availability,
    availabilityIndex,
    updateDays,
    updateIntervals,
    removeSchedule,
}) => {
    return (
        <VStack
            rounded="lg"
            borderWidth={1}
            borderColor="gray.100"
            spacing={2}
            flex={1}
            px={4}
            pb={2}
        >
            <Box pt={3} mb={4}>
                <Flex w="full" justify="space-between" align="center" mb={2}>
                    <Text
                        fontWeight="bold"
                        fontSize="xs"
                        textTransform="uppercase"
                        color="gray.500"
                    >
                        Days
                    </Text>
                    <Button
                        colorScheme="red"
                        size="sm"
                        variant="link"
                        onClick={() => removeSchedule(availabilityIndex)}
                    >
                        Delete
                    </Button>
                </Flex>
                <HStack>
                    {Object.values(Days).map(d => (
                        <DayButton
                            key={d}
                            day={d}
                            isSelected={availability.days.includes(d)}
                            onClick={() => {
                                updateDays(
                                    availabilityIndex,
                                    d,
                                    availability.days.includes(d)
                                )
                            }}
                        />
                    ))}
                </HStack>
            </Box>
            <IntervalsEdit
                intervals={availability.intervals}
                setIntervals={intervals =>
                    updateIntervals(availabilityIndex, intervals)
                }
            />
        </VStack>
    )
}

export default RepeatedAvailabilityForm
