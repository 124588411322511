import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { initializeApp, FirebaseOptions } from 'firebase/app'

export const app = initializeApp(getFirebaseConfig())
export const storage = getStorage(app)
export const auth = getAuth(app)

function getFirebaseConfig(): FirebaseOptions {
    switch (process.env.GATSBY_API_URL) {
        case 'https://api.chckup.app/graphql':
            return {
                apiKey: 'AIzaSyBxo9sas58G-9XzStMg6sG8JIkIidNHB78',
                authDomain: 'chckup-server.firebaseapp.com',
                projectId: 'chckup-server',
                storageBucket: 'chckup-server.appspot.com',
                messagingSenderId: '121035766291',
                appId: '1:121035766291:web:f1f8b4436a51cf2448dde6',
                measurementId: 'G-CHZY9FD1PD',
            }
        case 'https://staging.api.chckup.app/graphql':
            return {
                apiKey: 'AIzaSyBdnWXLxCBw6fylMmajMUMBUmaHknLnxCg',
                authDomain: 'chckup-server-staging.firebaseapp.com',
                projectId: 'chckup-server-staging',
                storageBucket: 'chckup-server-staging.appspot.com',
                messagingSenderId: '370833830512',
                appId: '1:370833830512:web:5ab5c559053d374318bab5',
                measurementId: 'G-NKWFDQP8NR',
            }
        case 'https://dev.api.chckup.app/graphql':
            return {
                apiKey: 'AIzaSyAXrp9C1aRpteNX2ksiDzbD_d0h1BlgvJ8',
                authDomain: 'chckup-server-dev.firebaseapp.com',
                projectId: 'chckup-server-dev',
                storageBucket: 'chckup-server-dev.appspot.com',
                messagingSenderId: '422357030216',
                appId: '1:422357030216:web:0b9d0877b366683c8bcf7d',
                measurementId: 'G-S9CQESMGL3',
            }
        case 'https://test.api.chckup.app':
            return {
                apiKey: 'AIzaSyA95y0c_a8tEjgNKvrKKWwl1mECn6dahbM',
                authDomain: 'chckup-server-test.firebaseapp.com',
                projectId: 'chckup-server-test',
                storageBucket: 'chckup-server-test.appspot.com',
                messagingSenderId: '1054204213605',
                appId: '1:1054204213605:web:1ddc2730c93102bc80c98c',
            }
        case 'http://localhost:4000/graphql':
            return {
                apiKey: 'AIzaSyAXrp9C1aRpteNX2ksiDzbD_d0h1BlgvJ8',
                authDomain: 'chckup-server-dev.firebaseapp.com',
                projectId: 'chckup-server-dev',
                storageBucket: 'chckup-server-dev.appspot.com',
                messagingSenderId: '422357030216',
                appId: '1:422357030216:web:0b9d0877b366683c8bcf7d',
                measurementId: 'G-S9CQESMGL3',
            }
        default:
            return {
                apiKey: 'AIzaSyAXrp9C1aRpteNX2ksiDzbD_d0h1BlgvJ8',
                authDomain: 'chckup-server-dev.firebaseapp.com',
                projectId: 'chckup-server-dev',
                storageBucket: 'chckup-server-dev.appspot.com',
                messagingSenderId: '422357030216',
                appId: '1:422357030216:web:0b9d0877b366683c8bcf7d',
                measurementId: 'G-S9CQESMGL3',
            }
    }
}
