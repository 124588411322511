import React, { useEffect, useState } from 'react'
import { Input, InputProps } from '@chakra-ui/input'

interface Props extends InputProps {
    time: number
    setTime: (time: number) => void
}

export default function TimeInput({ time, setTime, ...rest }: Props) {
    const [meridiem, setMeridiem] = useState<string>(
        Math.floor(time) >= 12 && Math.floor(time) !== 24 ? 'PM' : 'AM',
    )
    const [timeString, setTimeString] = useState<string>(
        formatTimeStringFromTime(time),
    )

    useEffect(() => {
        setTimeString(formatTimeStringFromTime(time))
    }, [time])

    function formatMinuteString(minute: number) {
        return minute < 10 ? `0${minute}` : minute
    }

    function formatTimeStringFromTime(time: number) {
        const hour = Math.floor(time)
        const minute = Math.round((time - hour) * 60)
        const meridiem = hour >= 12 && hour !== 24 ? 'PM' : 'AM'

        return `${hour % 12 || 12}:${formatMinuteString(minute)} ${meridiem}`
    }

    function handleBlur() {
        if (!timeString || timeString.length === 0) {
            setTimeString(formatTimeStringFromTime(time))
            return
        }

        const amPM =
            timeString.split('').findIndex(char => char.toLowerCase() === 'a') >
            -1
                ? 'AM'
                : timeString
                        .split('')
                        .findIndex(char => char.toLowerCase() === 'p') > -1
                  ? 'PM'
                  : undefined

        const timeArr =
            timeString.split(':').length > 1
                ? timeString.split(':')
                : timeString.split(' ')
        const hour =
            (Math.floor(parseFloat(timeArr[0])) % 12) +
            (amPM ? (amPM === 'PM' ? 12 : 0) : meridiem === 'PM' ? 12 : 0)
        const minute = timeArr[1]
            ? timeArr[1].split(' ').length > 1
                ? parseInt(timeArr[1].split(' ')[0])
                : 0
            : timeString.split(' ')[1]
              ? parseInt(timeString.split(' ')[1])
              : (parseFloat(timeArr[0]) - Math.floor(parseFloat(timeArr[0]))) *
                60

        setTime(hour + minute / 60)
        amPM && setMeridiem(amPM)

        setTimeString(
            formatTimeStringFromTime(
                (amPM === 'PM' && hour < 12 ? hour + 12 : hour) + minute / 60,
            ),
        )
    }

    return (
        <Input
            {...rest}
            value={timeString}
            onChange={e => setTimeString(e.target.value)}
            onBlur={handleBlur}
        />
    )
}
