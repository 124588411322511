import React from 'react'
import { Flex, Text, Icon, Button } from '@chakra-ui/react'
import { BiMinusCircle, BiPlusCircle } from 'react-icons/bi'

import TimeIntervalBlock from './time-interval-block'
import { TimeIntervalInput } from '~graphql/generated/graphql'

interface IntervalsEditProps {
    intervals: TimeIntervalInput[]
    setIntervals: (intervals: TimeIntervalInput[]) => void
    fullWidth?: boolean
}

const IntervalsEdit: React.FC<IntervalsEditProps> = ({
    intervals,
    setIntervals,
    fullWidth = false,
}) => {
    return (
        <>
            {intervals.length > 0 ? (
                intervals.map((hour, index) => {
                    return (
                        <Flex key={index} align="center">
                            <TimeIntervalBlock
                                hour={hour}
                                handleIntervalChange={(start, end) => {
                                    setIntervals([
                                        ...intervals.slice(0, index),
                                        {
                                            start,
                                            end,
                                        },
                                        ...intervals.slice(index + 1),
                                    ])
                                }}
                                fullWidth={fullWidth}
                            />
                            <Icon
                                as={BiMinusCircle}
                                ml={2}
                                boxSize="18px"
                                color="danger.400"
                                cursor="pointer"
                                onClick={() => {
                                    setIntervals([
                                        ...intervals.slice(0, index),
                                        ...intervals.slice(index + 1),
                                    ])
                                }}
                            />
                        </Flex>
                    )
                })
            ) : (
                <Text
                    w="266px"
                    flex={1}
                    lineHeight="40px"
                    textAlign="center"
                    fontSize="lg"
                    fontWeight="extrabold"
                    fontFamily="Nunito"
                >
                    No schedule set
                </Text>
            )}
            <Flex w="full">
                <Button
                    w="full"
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                        if (intervals.length === 0) {
                            setIntervals([
                                {
                                    start: 9,
                                    end: 17,
                                },
                            ])

                            return
                        }

                        setIntervals([
                            ...intervals,
                            {
                                start: intervals[intervals.length - 1].end,
                                end: intervals[intervals.length - 1].end,
                            },
                        ])
                    }}
                    leftIcon={<Icon as={BiPlusCircle} boxSize="18px" />}
                >
                    Add interval
                </Button>
            </Flex>
        </>
    )
}

export default IntervalsEdit
