import React from 'react'
import { Flex, Text } from '@chakra-ui/layout'

import TimeInput from './time-input'
import { TimeIntervalInput } from '~graphql/generated/graphql'

interface Props {
    hour: TimeIntervalInput
    handleIntervalChange: (start: number, end: number) => void
    fullWidth?: boolean
}

const TimeIntervalBlock: React.FC<Props> = ({
    hour,
    handleIntervalChange,
    fullWidth,
}) => {
    return (
        <Flex fontSize="14px" align="center">
            <TimeInput
                time={hour.start || 0}
                setTime={time => handleIntervalChange(time, hour.end || 0)}
                w={fullWidth ? 'full' : '108px'}
            />
            <Text mx={2} fontWeight="bold" fontSize="lg" fontFamily="Nunito">
                -
            </Text>
            <TimeInput
                time={hour.end || 0}
                setTime={time => handleIntervalChange(hour.start || 0, time)}
                w={fullWidth ? 'full' : '108px'}
            />
        </Flex>
    )
}

export default TimeIntervalBlock
