import React from 'react'
import { TabPanel } from '@chakra-ui/tabs'
import { VStack } from '@chakra-ui/layout'

import GradientDivider from '~components/shared/gradient-divider'
import { ClinicFieldsFragment } from '~graphql/generated/graphql'
import MessagePrioritySwitch from './message-priority-switch'
import AutoReplySettings from './auto-reply-settings'
import WritebackSettings from './writeback-settings'
import SaveActions from './save-actions'
import CommunicationSettingsProvider from './communication-settings-context'

export default function CommunicationSettingsPanel({
    clinic,
}: {
    clinic: ClinicFieldsFragment
}) {
    return (
        <TabPanel pb={0}>
            <CommunicationSettingsProvider clinic={clinic}>
                <VStack
                    w="full"
                    align="flex-start"
                    spacing={8}
                    pb={8}
                    pt={4}
                    divider={<GradientDivider />}
                    minH="100dvh"
                >
                    <MessagePrioritySwitch />
                    <AutoReplySettings />
                    <WritebackSettings />
                </VStack>
                <SaveActions />
            </CommunicationSettingsProvider>
        </TabPanel>
    )
}
