import React, {
    useMemo,
    useState,
    useEffect,
    useContext,
    createContext,
    PropsWithChildren,
} from 'react'

import {
    ClinicFieldsFragment,
    AppointmentEntryMethod,
    useUpdateClinicMutation,
    BookingMatchProcedure,
    UrgencyType,
} from '~graphql/generated/graphql'
import { SetStateFn } from '~config/constants'
import { handleMergeClinicSettings } from '../helpers'
import { useToastFeedback } from '~utils/hooks/use-toast-feedback'

export default function BookingSettingsProvider({
    children,
    clinic,
}: PropsWithChildren<{ clinic: ClinicFieldsFragment }>) {
    return (
        <BookingSettingsContext.Provider
            value={{ ...useBookingSettingsValues(clinic) }}
        >
            {children}
        </BookingSettingsContext.Provider>
    )
}

export function useBookingSettingsContext() {
    return useContext(BookingSettingsContext)
}

function useBookingSettingsValues(
    clinic: ClinicFieldsFragment,
): BookingSettingsContextType {
    const [appointmentEntry, setAppointmentEntry] =
        useState<AppointmentEntryMethod>(
            AppointmentEntryMethod.ConfirmationRequired,
        )
    const [isUsingDetailedServices, setIsUsingDetailedServices] =
        useState<boolean>(false)
    const [minMinutesAway, setMinMinutesAway] = useState<number>(0)
    const [maxMinutesAway, setMaxMinutesAway] = useState<number>(0)
    const [timeSlotMinutes, setTimeSlotMinutes] = useState<number>(0)
    const [sendEmailOnNewAppt, setSendEmailOnNewAppt] = useState<boolean>(false)
    const [hideClinicianSelect, setHideClinicianSelect] =
        useState<boolean>(false)
    const [doesUseRoomsToSchedule, setDoesUseRoomsToSchedule] =
        useState<boolean>(false)
    const [doesUseCliniciansToSchedule, setDoesUseCliniciansToSchedule] =
        useState<boolean>(false)
    const [allowFirstApptPerDay, setAllowFirstApptPerDay] =
        useState<boolean>(false)
    const [defaultTriggerWords, setDefaultTriggerWords] = useState<string>('')
    const [shouldAllowNewPatients, setShouldAllowNewPatients] =
        useState<boolean>(false)
    const [shouldAllowNewClients, setShouldAllowNewClients] =
        useState<boolean>(false)
    const [shouldCreatePatients, setShouldCreatePatients] =
        useState<boolean>(false)
    const [shouldCreateClients, setShouldCreateClients] =
        useState<boolean>(false)
    const [requireNewClientAddress, setRequireNewClientAddress] =
        useState<boolean>(false)
    const [bookingMatchProcedure, setBookingMatchProcedure] =
        useState<BookingMatchProcedure>(
            BookingMatchProcedure.EnterAllInformation,
        )
    const [urgencyType, setUrgencyType] = useState<UrgencyType>(
        UrgencyType.GeneralPractice,
    )
    const [maxBookingColumns, setMaxBookingColumns] = useState<number>(7)
    const [shouldDefaultPimsEmailsOn, setShouldDefaultPimsEmailsOn] =
        useState<boolean>(false)
    const [shouldDefaultPimsSmsOn, setShouldDefaultPimsSmsOn] =
        useState<boolean>(false)

    const [customNotesTemplate, setCustomNotesTemplate] = useState<string>('')
    const [customNewPatientNotesTemplate, setCustomNewPatientNotesTemplate] =
        useState<string>('')
    const [customNewClientNotesTemplate, setCustomNewClientNotesTemplate] =
        useState<string>('')

    useEffect(() => {
        if (!clinic?.settings) return
        const settings = clinic.settings

        setAppointmentEntry(
            settings.appointment_entry_method ||
                AppointmentEntryMethod.ConfirmationRequired,
        )
        setIsUsingDetailedServices(settings.is_using_detailed_services || false)
        setMinMinutesAway(settings.scheduling_min_minutes_away || 0)
        setMaxMinutesAway(settings.scheduling_max_minutes_away || 0)
        setTimeSlotMinutes(settings.time_slot_minutes || 0)
        setSendEmailOnNewAppt(!!settings.send_new_appt_email)
        setHideClinicianSelect(!!settings.hide_clinician_preference)
        setDoesUseRoomsToSchedule(!!settings.does_use_rooms_to_schedule)
        setDoesUseCliniciansToSchedule(
            !!settings.does_use_clinicians_to_schedule,
        )
        setAllowFirstApptPerDay(!!settings.allow_first_appt_per_day)
        setDefaultTriggerWords(settings.default_trigger_words?.join(', ') || '')
        setShouldAllowNewPatients(!!settings.should_allow_new_patients)
        setShouldAllowNewClients(!!settings.should_allow_new_clients)
        setShouldCreatePatients(!!settings.should_create_patients)
        setShouldCreateClients(!!settings.should_create_clients)
        setRequireNewClientAddress(!!settings.does_require_new_client_address)
        setBookingMatchProcedure(
            settings.booking_match_procedure ||
                BookingMatchProcedure.EnterAllInformation,
        )
        setUrgencyType(settings.urgency_type || UrgencyType.GeneralPractice)
        setMaxBookingColumns(settings.max_booking_columns || 7)
        setShouldDefaultPimsEmailsOn(settings.should_default_pims_emails_on)
        setShouldDefaultPimsSmsOn(settings.should_default_pims_sms_on)
        setCustomNotesTemplate(settings.custom_appointment_notes_template || '')
        setCustomNewPatientNotesTemplate(
            settings.custom_new_patient_notes_template || '',
        )
        setCustomNewClientNotesTemplate(
            settings.custom_new_client_notes_template || '',
        )
    }, [clinic?.settings])

    const canSave = useMemo(() => {
        if (!clinic.settings) return false

        const settings = clinic.settings
        return (
            appointmentEntry !==
                (settings?.appointment_entry_method ||
                    AppointmentEntryMethod.ConfirmationRequired) ||
            isUsingDetailedServices !==
                (settings?.is_using_detailed_services || false) ||
            minMinutesAway !== settings?.scheduling_min_minutes_away ||
            maxMinutesAway !== settings?.scheduling_max_minutes_away ||
            timeSlotMinutes !== settings?.time_slot_minutes ||
            sendEmailOnNewAppt !== settings?.send_new_appt_email ||
            hideClinicianSelect !== settings?.hide_clinician_preference ||
            doesUseRoomsToSchedule !== settings?.does_use_rooms_to_schedule ||
            doesUseCliniciansToSchedule !==
                settings?.does_use_clinicians_to_schedule ||
            allowFirstApptPerDay !== settings?.allow_first_appt_per_day ||
            defaultTriggerWords !==
                settings?.default_trigger_words?.join(', ') ||
            shouldAllowNewPatients !== settings?.should_allow_new_patients ||
            shouldAllowNewClients !== settings?.should_allow_new_clients ||
            shouldCreatePatients !== settings?.should_create_patients ||
            shouldCreateClients !== settings?.should_create_clients ||
            requireNewClientAddress !==
                settings?.does_require_new_client_address ||
            bookingMatchProcedure !== settings?.booking_match_procedure ||
            urgencyType !== settings?.urgency_type ||
            shouldDefaultPimsEmailsOn !==
                settings.should_default_pims_emails_on ||
            shouldDefaultPimsSmsOn !== settings.should_default_pims_sms_on ||
            customNotesTemplate !==
                settings.custom_appointment_notes_template ||
            customNewPatientNotesTemplate !==
                settings.custom_new_patient_notes_template ||
            customNewClientNotesTemplate !==
                settings.custom_new_client_notes_template ||
            maxBookingColumns !== settings.max_booking_columns
        )
    }, [
        clinic?.settings,
        appointmentEntry,
        isUsingDetailedServices,
        minMinutesAway,
        maxMinutesAway,
        timeSlotMinutes,
        sendEmailOnNewAppt,
        hideClinicianSelect,
        doesUseRoomsToSchedule,
        doesUseCliniciansToSchedule,
        allowFirstApptPerDay,
        defaultTriggerWords,
        shouldAllowNewPatients,
        shouldAllowNewClients,
        shouldCreatePatients,
        shouldCreateClients,
        requireNewClientAddress,
        bookingMatchProcedure,
        urgencyType,
        maxBookingColumns,
        shouldDefaultPimsEmailsOn,
        shouldDefaultPimsSmsOn,
        customNotesTemplate,
        customNewPatientNotesTemplate,
        customNewClientNotesTemplate,
    ])

    const toasts = useToastFeedback(
        'Successfully updated booking settings',
        'Error updating booking settings',
    )

    const [handleUpdate, { loading }] = useUpdateClinicMutation({
        variables: {
            id: clinic?.id || '',
            data: {
                settings: {
                    appointment_entry_method:
                        appointmentEntry as AppointmentEntryMethod,
                    is_using_detailed_services: isUsingDetailedServices,
                    scheduling_min_minutes_away: minMinutesAway,
                    scheduling_max_minutes_away: maxMinutesAway,
                    time_slot_minutes: timeSlotMinutes,
                    send_new_appt_email: sendEmailOnNewAppt,
                    hide_clinician_preference: hideClinicianSelect,
                    does_use_rooms_to_schedule: doesUseRoomsToSchedule,
                    does_use_clinicians_to_schedule:
                        doesUseCliniciansToSchedule,
                    allow_first_appt_per_day: allowFirstApptPerDay,
                    default_trigger_words: defaultTriggerWords
                        .split(',')
                        .map(s => s.trim())
                        .filter(Boolean),
                    should_allow_new_patients: shouldAllowNewPatients,
                    should_allow_new_clients: shouldAllowNewClients,
                    should_create_patients: shouldCreatePatients,
                    should_create_clients: shouldCreateClients,
                    does_require_new_client_address: requireNewClientAddress,
                    booking_match_procedure: bookingMatchProcedure,
                    urgency_type: urgencyType,
                    max_booking_columns: maxBookingColumns,
                    should_default_pims_emails_on: shouldDefaultPimsEmailsOn,
                    should_default_pims_sms_on: shouldDefaultPimsSmsOn,
                    custom_appointment_notes_template: customNotesTemplate,
                    custom_new_patient_notes_template:
                        customNewPatientNotesTemplate,
                    custom_new_client_notes_template:
                        customNewClientNotesTemplate,
                },
            },
        },
        update: (cache, res) => {
            handleMergeClinicSettings(clinic, cache, res)
        },
        ...toasts,
    })

    return {
        // Context Values
        canSave,
        isUpdateLoading: loading,

        // Actions
        handleUpdateSettings: handleUpdate,

        // Setting Values
        appointmentEntry,
        setAppointmentEntry,
        isUsingDetailedServices,
        setIsUsingDetailedServices,
        minMinutesAway,
        setMinMinutesAway,
        maxMinutesAway,
        setMaxMinutesAway,
        timeSlotMinutes,
        setTimeSlotMinutes,
        sendEmailOnNewAppt,
        setSendEmailOnNewAppt,
        hideClinicianSelect,
        setHideClinicianSelect,
        doesUseRoomsToSchedule,
        setDoesUseRoomsToSchedule,
        doesUseCliniciansToSchedule,
        setDoesUseCliniciansToSchedule,
        allowFirstApptPerDay,
        setAllowFirstApptPerDay,
        defaultTriggerWords,
        setDefaultTriggerWords,
        shouldAllowNewPatients,
        setShouldAllowNewPatients,
        shouldAllowNewClients,
        setShouldAllowNewClients,
        shouldCreatePatients,
        setShouldCreatePatients,
        shouldCreateClients,
        setShouldCreateClients,
        requireNewClientAddress,
        setRequireNewClientAddress,
        bookingMatchProcedure,
        setBookingMatchProcedure,
        urgencyType,
        setUrgencyType,
        maxBookingColumns,
        setMaxBookingColumns,
        shouldDefaultPimsEmailsOn,
        setShouldDefaultPimsEmailsOn,
        shouldDefaultPimsSmsOn,
        setShouldDefaultPimsSmsOn,
        customNotesTemplate,
        setCustomNotesTemplate,
        customNewPatientNotesTemplate,
        setCustomNewPatientNotesTemplate,
        customNewClientNotesTemplate,
        setCustomNewClientNotesTemplate,
    }
}

type BookingSettingsContextType = {
    // Context Values
    canSave: boolean
    isUpdateLoading: boolean

    // Actions
    handleUpdateSettings: () => Promise<any>

    // Setting Values
    appointmentEntry: AppointmentEntryMethod
    setAppointmentEntry: SetStateFn<AppointmentEntryMethod>
    isUsingDetailedServices: boolean
    setIsUsingDetailedServices: SetStateFn<boolean>
    minMinutesAway: number
    setMinMinutesAway: SetStateFn<number>
    maxMinutesAway: number
    setMaxMinutesAway: SetStateFn<number>
    timeSlotMinutes: number
    setTimeSlotMinutes: SetStateFn<number>
    sendEmailOnNewAppt: boolean
    setSendEmailOnNewAppt: SetStateFn<boolean>
    hideClinicianSelect: boolean
    setHideClinicianSelect: SetStateFn<boolean>
    doesUseRoomsToSchedule: boolean
    setDoesUseRoomsToSchedule: SetStateFn<boolean>
    doesUseCliniciansToSchedule: boolean
    setDoesUseCliniciansToSchedule: SetStateFn<boolean>
    allowFirstApptPerDay: boolean
    setAllowFirstApptPerDay: SetStateFn<boolean>
    defaultTriggerWords: string
    setDefaultTriggerWords: SetStateFn<string>
    shouldAllowNewPatients: boolean
    setShouldAllowNewPatients: SetStateFn<boolean>
    shouldAllowNewClients: boolean
    setShouldAllowNewClients: SetStateFn<boolean>
    shouldCreatePatients: boolean
    setShouldCreatePatients: SetStateFn<boolean>
    shouldCreateClients: boolean
    setShouldCreateClients: SetStateFn<boolean>
    requireNewClientAddress: boolean
    setRequireNewClientAddress: SetStateFn<boolean>
    bookingMatchProcedure: BookingMatchProcedure
    setBookingMatchProcedure: SetStateFn<BookingMatchProcedure>
    urgencyType: UrgencyType
    setUrgencyType: SetStateFn<UrgencyType>
    maxBookingColumns: number
    setMaxBookingColumns: SetStateFn<number>
    shouldDefaultPimsEmailsOn: boolean
    setShouldDefaultPimsEmailsOn: SetStateFn<boolean>
    shouldDefaultPimsSmsOn: boolean
    setShouldDefaultPimsSmsOn: SetStateFn<boolean>
    customNotesTemplate: string
    setCustomNotesTemplate: SetStateFn<string>
    customNewPatientNotesTemplate: string
    setCustomNewPatientNotesTemplate: SetStateFn<string>
    customNewClientNotesTemplate: string
    setCustomNewClientNotesTemplate: SetStateFn<string>
}

const BookingSettingsContext = createContext<BookingSettingsContextType>({
    // Context Values
    canSave: false,
    isUpdateLoading: false,

    // Actions
    handleUpdateSettings: () => Promise.resolve(),

    // Setting Values
    appointmentEntry: AppointmentEntryMethod.ConfirmationRequired,
    setAppointmentEntry: () => {},
    isUsingDetailedServices: false,
    setIsUsingDetailedServices: () => {},
    minMinutesAway: 0,
    setMinMinutesAway: () => {},
    maxMinutesAway: 0,
    setMaxMinutesAway: () => {},
    timeSlotMinutes: 0,
    setTimeSlotMinutes: () => {},
    sendEmailOnNewAppt: false,
    setSendEmailOnNewAppt: () => {},
    hideClinicianSelect: false,
    setHideClinicianSelect: () => {},
    doesUseRoomsToSchedule: false,
    setDoesUseRoomsToSchedule: () => {},
    doesUseCliniciansToSchedule: false,
    setDoesUseCliniciansToSchedule: () => {},
    allowFirstApptPerDay: false,
    setAllowFirstApptPerDay: () => {},
    defaultTriggerWords: '',
    setDefaultTriggerWords: () => {},
    shouldAllowNewPatients: false,
    setShouldAllowNewPatients: () => {},
    shouldAllowNewClients: false,
    setShouldAllowNewClients: () => {},
    shouldCreatePatients: false,
    setShouldCreatePatients: () => {},
    shouldCreateClients: false,
    setShouldCreateClients: () => {},
    requireNewClientAddress: false,
    setRequireNewClientAddress: () => {},
    bookingMatchProcedure: BookingMatchProcedure.EnterAllInformation,
    setBookingMatchProcedure: () => {},
    urgencyType: UrgencyType.GeneralPractice,
    setUrgencyType: () => {},
    maxBookingColumns: 7,
    setMaxBookingColumns: () => {},
    shouldDefaultPimsEmailsOn: false,
    setShouldDefaultPimsEmailsOn: () => {},
    shouldDefaultPimsSmsOn: false,
    setShouldDefaultPimsSmsOn: () => {},
    customNotesTemplate: '',
    setCustomNotesTemplate: () => {},
    customNewPatientNotesTemplate: '',
    setCustomNewPatientNotesTemplate: () => {},
    customNewClientNotesTemplate: '',
    setCustomNewClientNotesTemplate: () => {},
})
