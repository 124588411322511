export function getMinutesFromMap(
    value: string,
    map: { [key: number]: string },
) {
    const key =
        Object.keys(map).find(key => map[Number.parseInt(key)] === value) ||
        '30'

    const number = Number.parseInt(key)

    return number
}

export const minTimeOptions: {
    [key: number]: string
} = {
    0: 'None',
    30: '30 minutes',
    60: '1 hour',
    90: '1 hour 30 minutes',
    120: '2 hours',
    240: '4 hours',
    360: '6 hours',
    720: '12 hours',
    1440: '1 day',
    2880: '2 days',
    4320: '3 days',
    10080: '1 week',
    20160: '2 weeks',
}

export const maxBookingColumnsOptions: {
    [key: number]: string
} = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
}

export const maxTimeOptionsAll: {
    [key: number]: string
} = {
    0: 'None',
    30: '30 minutes',
    60: '1 hour',
    90: '1 hour 30 minutes',
    120: '2 hours',
    240: '4 hours',
    360: '6 hours',
    720: '12 hours',
    1440: '1 day',
    2880: '2 days',
    4320: '3 days',
    10080: '1 week',
    20160: '2 weeks',
    43200: '1 month',
    86400: '2 months',
    129600: '3 months',
    262800: '6 months',
    525600: '1 year',
    1051200: '2 years',
}

export const maxTimeOptionsClinic: {
    [key: number]: string
} = {
    1440: '1 day',
    2880: '2 days',
    4320: '3 days',
    10080: '1 week',
    20160: '2 weeks',
    43200: '1 month',
    86400: '2 months',
    129600: '3 months',
    262800: '6 months',
    525600: '1 year',
    1051200: '2 years',
}

export const confirmationDaysBeforeOptions: {
    [key: number]: string
} = {
    1: '1 day',
    2: '2 days',
    3: '3 days',
    4: '4 days',
    5: '5 days',
    6: '6 days',
    7: '1 week',
    14: '2 weeks',
}

export const patientAgeOptions: { [key: number]: string } = {
    60: '2 months',
    120: '4 months',
    182: '6 months',
    365: '1 year',
    730: '2 years',
    1825: '5 years',
}

export const timeSlotOptions: { [key: number]: string } = {
    10: '10 minutes',
    15: '15 minutes',
    20: '20 minutes',
    30: '30 minutes',
    45: '45 minutes',
    60: '1 hour',
    75: '1 hour 15 minutes',
    90: '1 hour 30 minutes',
    120: '2 hours',
}

export const writebackBufferOptions: Record<number, string> = {
    [-1]: 'No buffer',
    60: '1 hour',
    120: '2 hours',
    240: '4 hours',
    480: '8 hours',
    720: '12 hours',
    1440: '24 hours',
    2880: '2 days',
    4320: '3 days',
    8640: '4 days',
    10080: '1 week',
    20160: '2 weeks',
}

export const writebackBeforeApptOptions: Record<number, string> = {
    [-1]: 'No buffer',
    60: '1 hour',
    120: '2 hours',
    240: '4 hours',
    480: '8 hours',
    720: '12 hours',
    1440: '24 hours',
    2880: '2 days',
    4320: '3 days',
    8640: '4 days',
    10080: '1 week',
    20160: '2 weeks',
}
