import React from 'react'
import { Center, Text } from '@chakra-ui/layout'

import { Days } from '~utils/availability-helpers'

interface Props {
    day: Days
    isSelected?: boolean
    onClick: () => void
}

const DayButton: React.FC<Props> = ({ day, isSelected, onClick }) => {
    function getDayAbbreviation(day: Days) {
        switch (day) {
            case Days.monday:
                return 'M'
            case Days.tuesday:
                return 'T'
            case Days.wednesday:
                return 'W'
            case Days.thursday:
                return 'Th'
            case Days.friday:
                return 'F'
            case Days.saturday:
                return 'Sa'
            case Days.sunday:
                return 'Su'
        }
    }

    return (
        <Center
            rounded="full"
            bg={isSelected ? 'brand.500' : 'transparent'}
            _hover={{
                bg: isSelected ? 'brand.600' : 'gray.100',
            }}
            cursor="pointer"
            w={8}
            h={8}
            onClick={onClick}
        >
            <Text fontWeight="bold" color={isSelected ? 'gray.50' : 'gray.900'}>
                {getDayAbbreviation(day)}
            </Text>
        </Center>
    )
}

export default DayButton
