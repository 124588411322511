import React, { useState } from 'react'
import {
    Box,
    Text,
    HStack,
    SimpleGrid,
    GridItem,
    SimpleGridProps,
} from '@chakra-ui/layout'

import IntervalColumn from './interval-column'
import { Days } from '~utils/availability-helpers'
import { AvailabilityHoursInput } from '~graphql/generated/graphql'
import { Switch } from '@chakra-ui/react'
import RepeatedAvailabilityForm from './repeated-availability'

interface Props {
    hours: AvailabilityHoursInput | undefined
    setHours: (e: AvailabilityHoursInput) => void
    useIndividual?: boolean
    setUseIndividual?: (e: boolean) => void
    gridProps?: SimpleGridProps
}

const Availability: React.FC<Props> = ({
    hours,
    setHours,
    useIndividual: outerUseIndividual,
    setUseIndividual: outerSetUseIndividual,
    gridProps,
}) => {
    const { useIndividual, setUseIndividual } = useStateIfNoProps({
        useIndividual: outerUseIndividual,
        setUseIndividual: outerSetUseIndividual,
    })

    return (
        <Box mt={2}>
            {!outerSetUseIndividual && (
                <HStack mb={4}>
                    <Text>Set days individually?</Text>
                    <Switch
                        colorScheme="brand"
                        isChecked={useIndividual}
                        onChange={e => setUseIndividual(e.target.checked)}
                    />
                </HStack>
            )}
            {!useIndividual ? (
                <RepeatedAvailabilityForm
                    hours={hours}
                    setHours={setHours}
                    gridProps={gridProps}
                />
            ) : (
                <SimpleGrid
                    columns={{ base: 1, md: 2, xl: 3 }}
                    gridGap={6}
                    {...gridProps}
                >
                    {Object.values(Days).map(day => {
                        return (
                            <GridItem key={day}>
                                <IntervalColumn
                                    hours={hours}
                                    setHours={setHours}
                                    day={day as keyof AvailabilityHoursInput}
                                />
                            </GridItem>
                        )
                    })}
                </SimpleGrid>
            )}
        </Box>
    )
}

export default Availability

function useStateIfNoProps({
    useIndividual,
    setUseIndividual,
}: {
    useIndividual?: boolean
    setUseIndividual?: (e: boolean) => void
}) {
    const [individualState, setIndividualState] = useState(false)
    return {
        useIndividual: useIndividual ?? individualState,
        setUseIndividual: setUseIndividual ?? setIndividualState,
    }
}
