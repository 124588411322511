import React, { useMemo } from 'react'
import { BiMinusCircle, BiPlusCircle } from 'react-icons/bi'
import { VStack, Flex, Icon, Box, Button, Text } from '@chakra-ui/react'

import { AvailabilityHoursInput } from '~graphql/generated/graphql'
import TimeIntervalBlock from './time-interval-block'

interface Props {
    hours?: AvailabilityHoursInput
    setHours: (e: AvailabilityHoursInput) => void
    day: keyof AvailabilityHoursInput
}

const IntervalColumn: React.FC<Props> = ({ hours, setHours, day }) => {
    const intervals = useMemo(
        () => (hours ? hours[day] || [] : []),
        [hours, day]
    )

    // Given the name of a day, give the name of the previous day
    const getPreviousDay = (day: keyof AvailabilityHoursInput) => {
        switch (day) {
            case 'monday':
                return 'sunday'
            case 'tuesday':
                return 'monday'
            case 'wednesday':
                return 'tuesday'
            case 'thursday':
                return 'wednesday'
            case 'friday':
                return 'thursday'
            case 'saturday':
                return 'friday'
            case 'sunday':
                return 'saturday'
            default:
                return 'monday'
        }
    }

    return (
        <VStack
            key={day}
            rounded="lg"
            borderWidth={1}
            borderColor="gray.100"
            spacing={2}
            flex={1}
            px={4}
            py={2}
        >
            <Box w="100%">
                <Text
                    key={day}
                    fontSize="md"
                    color={intervals.length === 0 ? 'gray.500' : 'brand.500'}
                    fontWeight="extrabold"
                    textTransform="capitalize"
                >
                    {day}
                </Text>
                <Text fontSize="sm" color="gray.500">
                    {intervals.length === 0
                        ? `Add time intervals to your schedule for ${day}`
                        : `Input the time intervals you are available on ${day}`}
                </Text>
            </Box>
            {intervals.length > 0 ? (
                intervals.map((hour, index) => {
                    const handleIntervalChange = (
                        start: number,
                        end: number
                    ) => {
                        setHours({
                            ...hours,
                            [day]: [
                                ...intervals.slice(0, index),
                                {
                                    start,
                                    end,
                                },
                                ...intervals.slice(index + 1),
                            ],
                        })
                    }

                    return (
                        <Flex key={index} align="center">
                            <TimeIntervalBlock
                                hour={hour}
                                handleIntervalChange={handleIntervalChange}
                            />
                            <Icon
                                as={BiMinusCircle}
                                ml={2}
                                boxSize="18px"
                                color="danger.400"
                                cursor="pointer"
                                onClick={() => {
                                    setHours({
                                        ...hours,
                                        [day]: [
                                            ...intervals.slice(0, index),
                                            ...intervals.slice(index + 1),
                                        ],
                                    })
                                }}
                            />
                        </Flex>
                    )
                })
            ) : (
                <Text
                    w="266px"
                    lineHeight="40px"
                    textAlign="center"
                    fontSize="lg"
                    fontWeight="extrabold"
                    fontFamily="Nunito"
                >
                    No schedule set
                </Text>
            )}
            <Box w="full">
                <Button
                    w="full"
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                        if (
                            intervals.length === 0 &&
                            hours &&
                            hours[getPreviousDay(day)] &&
                            hours[getPreviousDay(day)]?.length !== 0
                        ) {
                            setHours({
                                ...hours,
                                [day]: hours[getPreviousDay(day)] || [],
                            })

                            return
                        }

                        setHours({
                            ...hours,
                            [day]: [
                                ...intervals,
                                {
                                    start:
                                        intervals[intervals.length - 1]?.end ||
                                        9,
                                    end:
                                        intervals[intervals.length - 1]?.end ||
                                        17,
                                },
                            ],
                        })
                    }}
                >
                    <Icon as={BiPlusCircle} boxSize="18px" mr={2} />
                    Add time interval
                </Button>
            </Box>
        </VStack>
    )
}

export default IntervalColumn
