import { AvailabilityHours, TimeInterval } from '~graphql/generated/graphql'
import { cleanTypenames } from './helpers'

export enum Days {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}

export type RepeatedAvailability = {
    intervals: TimeInterval[]
    days: Days[]
}

export function getRepeatedAvailability(
    hours: AvailabilityHours
): RepeatedAvailability[] {
    let availabilities: RepeatedAvailability[] = [
        {
            intervals: hours.monday
                ? hours.monday.map(i => cleanTypenames(i))
                : [],
            days: [Days.monday],
        },
    ]
    Object.values(Days)
        .filter(k => k !== 'monday')
        .forEach(d => {
            const index = availabilities.findIndex(a =>
                !hours[d] || (hours[d] && hours[d]?.length === 0)
                    ? a.intervals.length === 0
                    : JSON.stringify(cleanTypenames(a.intervals)) ===
                      JSON.stringify(cleanTypenames(hours[d] || {}))
            )

            if (index !== -1) {
                availabilities[index].days.push(d)
            } else {
                availabilities.push({
                    intervals: hours[d]
                        ? hours[d]?.map(i => cleanTypenames(i)) || []
                        : [],
                    days: [d],
                })
            }
        })

    return availabilities
}

export function getAvailabilityHours(
    availabilities: RepeatedAvailability[]
): AvailabilityHours {
    let hours: AvailabilityHours = {}
    availabilities.forEach(a => {
        a.days.forEach(d => {
            hours[d] = a.intervals
        })
    })

    return hours
}

export function toUTCNoon(date: Date | number) {
    const d = new Date(date)
    d.setUTCHours(12, 0, 0, 0)
    return d
}
