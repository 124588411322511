import React from 'react'
import SettingSwitch from '../form/setting-switch'
import { useCommunicationSettingsContext } from './communication-settings-context'

export default function MessagePrioritySwitch() {
    const { useMsgAutoPriority, setUseMsgAutoPriority } =
        useCommunicationSettingsContext()

    return (
        <SettingSwitch
            label="Use automatic message priority?"
            isChecked={useMsgAutoPriority}
            onChange={e => setUseMsgAutoPriority(e.target.checked)}
        />
    )
}
