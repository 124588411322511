import React from 'react'
import { Box, Button, Icon } from '@chakra-ui/react'
import { Link } from 'gatsby'
import { FiExternalLink } from 'react-icons/fi'

import SharedInput from '~components/shared/form/shared-input'
import SharedStack from '~components/shared/wrappers/shared-stack'
import { useClinic } from '~contexts/clinic-context'
import SettingSwitch from '../form/setting-switch'
import { useCommunicationSettingsContext } from './communication-settings-context'
import SharedLabel from '~components/shared/form/shared-label'
import SharedHelpText from '~components/shared/form/shared-help-text'
import { UserRoutes } from '~config/routes'
import Availability from '~components/shared/availability'

export default function AutoReplySettings() {
    const { clinic } = useClinic()
    const {
        isAutoReplyEnabled,
        setIsAutoReplyEnabled,
        autoReplyMessage,
        setAutoReplyMessage,
        autoReplyHours,
        setAutoReplyHours,
    } = useCommunicationSettingsContext()

    return (
        <SharedStack spacing={8}>
            <SettingSwitch
                label="Enable auto-reply for incoming SMS messages?"
                isChecked={isAutoReplyEnabled}
                onChange={e => setIsAutoReplyEnabled(e.target.checked)}
            />
            <SharedInput
                isDisabled={!isAutoReplyEnabled}
                label="Custom auto-reply message"
                labelProps={{
                    fontSize: 'md',
                }}
                value={autoReplyMessage}
                onChange={e => setAutoReplyMessage(e.target.value)}
                placeholder={`Thank you for reaching out to ${
                    clinic?.name || 'us'
                }. This number is not monitored. Please call ${
                    clinic?.phone || 'our main number'
                } for assistance.`}
                containerProps={{ width: '100%' }}
            />
            <SharedStack>
                <Box>
                    <SharedLabel fontSize="md">Auto Reply Hours</SharedLabel>
                    <SharedHelpText fontSize="sm">
                        Auto-reply messages will only be sent between the hours
                        you select.
                    </SharedHelpText>
                </Box>
                <Availability
                    hours={autoReplyHours || undefined}
                    setHours={h => {
                        const hoursIsEmpty = Object.keys(h || {}).every(
                            day => !h[day as keyof typeof h]?.length,
                        )
                        if (hoursIsEmpty) {
                            setAutoReplyHours(null)
                        } else {
                            setAutoReplyHours(h)
                        }
                    }}
                />
            </SharedStack>
        </SharedStack>
    )
}
